.root {
  
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.hello {
  text-align: left;
  font-size: 8px;
  width: 100%;
  height: 100%;
  
  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }
  ol {
    padding:0 1rem;
  }
  li {
    font-size: 10px !important;
    line-height: 1.5;
  };

  p {
    font-size: 10px !important;
    margin: 0;
    margin-bottom: 8px;
    line-height: 1.5;
  };

  pre {
    font-size: 8px !important;
  }
}

.myTippy {
  border-radius: 5% !important;
}
